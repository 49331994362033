import React, { useState, useEffect, useMemo } from "react";

import Button from "../../Components/Button";
import info from "../assests/Info.webp";
import eco from "../assests/eco.gif";
import fair from "../assests/fair.gif";
import { ReactComponent as Verify } from "./assests/quality.svg";
import { ReactComponent as Eco } from "./assests/eco.svg";
import { ReactComponent as Fair } from "./assests/fair.svg";
import carQuality from "./assests/carsQuality.gif";

const Info = () => {
  const [activeButton, setActiveButton] = useState("Quality Checks");
  const buttonNames = useMemo(
    () => ["Quality Checks", "Eco-Friendly", "Fair Prices"],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveButton((prev) => {
        const currentIndex = buttonNames.indexOf(prev);
        const nextIndex = (currentIndex + 1) % buttonNames.length;
        return buttonNames[nextIndex];
      });
    }, 10000);
    return () => clearInterval(interval);
  }, [buttonNames]);

  return (
    <>
      <img
        src={info}
        alt="info"
        className="mx-auto absolute min-h-screen w-full lg:h-[110vh]"
      />
      <div className="relative flex items-center justify-center flex-col mx-auto min-h-screen lg:my-10 pt-10 md:pt-0">
        <div className="text-center font-poppins">
          <p className="text-sm font-medium pb-3 lg:text-xl">Why Choose Us?</p>
          <p className="text-2xl font-semibold pb-3 lg:text-6xl">
            Largest Inventory in <span className="text-red">Tamilnadu</span>
          </p>
          <p className="text-sm font-medium pb-3 lg:text-xl mt-3">
            Find parts for any car!
          </p>
        </div>
        <div className="flex items-center justify-center gap-5 pt-3 flex-col md:flex-row">
          <div>
            <Button
              text="Quality Checks"
              icon={<Verify />}
              isActive={activeButton === "Quality Checks"}
              onClick={() => setActiveButton("Quality Checks")}
            />
          </div>
          <div>
            <Button
              text="Eco-Friendly"
              icon={<Eco />}
              isActive={activeButton === "Eco-Friendly"}
              onClick={() => setActiveButton("Eco-Friendly")}
            />
          </div>
          <div>
            <Button
              text="Fair Prices"
              icon={<Fair />}
              isActive={activeButton === "Fair Prices"}
              onClick={() => setActiveButton("Fair Prices")}
            />
          </div>
        </div>
        {["Quality Checks", "Eco-Friendly", "Fair Prices"].map((buttonName) => (
          <div
            style={{
              transition: "opacity 0.5s ease",
              opacity: activeButton === buttonName ? 1 : 0,
            }}
          >
            {activeButton === buttonName && buttonName === "Quality Checks" && (
              <div className="text-center font-poppins pt-10 relative">
                <p className="font-medium text-4xl pb-2">
                  Rigorous Quality Checks
                </p>
                <p className="md:font-normal md:text-sm text-xs pt-3">
                  Every part is inspected for functionality and reliability.
                </p>
                <p className="font-bold text-xl pt-3 text-red">
                  100% Genuine Spares
                </p>
                <div className="flex items-center justify-center flex-wrap relative mt-16">
                  <img
                    src={carQuality}
                    alt="eco"
                    className="mx-auto relative w-full h-96"
                  />
                </div>
              </div>
            )}
            {activeButton === buttonName && buttonName === "Eco-Friendly" && (
              <div className="text-center font-poppins pt-10 relative">
                <p className="font-medium text-4xl pb-2">
                  Eco-Friendly at an Affordable Price
                </p>
                <p className="md:font-normal md:text-sm text-xs pt-3">
                  Reduce waste and protect the environment affordably with
                  <span className="text-red md:font-extrabold md:text-base">
                    Immediate Availability
                  </span>
                  .
                </p>
                <div className="flex items-center justify-center flex-wrap relative mt-16">
                  <img
                    src={eco}
                    alt="eco"
                    className="mx-auto relative w-full h-96"
                  />
                </div>
              </div>
            )}
            {activeButton === buttonName && buttonName === "Fair Prices" && (
              <div className="text-center font-poppins pt-10 relative">
                <p className="font-medium text-4xl pb-2">
                  Best Value for your Scrap Car.
                </p>
                <p className="md:font-normal md:text-sm text-xs pt-3">
                  Get a fair price and hassle-free removal of your{" "}
                  <span className="text-red">scrap car</span>.
                </p>
                <div className="flex items-center justify-center flex-wrap relative mt-16">
                  <img
                    src={fair}
                    alt="fair"
                    className="mx-auto relative w-full h-96"
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default Info;
