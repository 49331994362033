import React from "react";
import { NavLink } from "react-router-dom";

import about from "./assets/AboutBanner.webp";
import quality from "./assets/quality.gif";
import aboutus from "./assets/aboutus.gif";
import ceo from "./assets/kunja.jpg";
import Team from "./Components/team";
import logoVideo from "./assets/logovideo.mp4";

const AboutUs = () => {
  return (
    <>
      <div className="relative h-96">
        <img src={about} alt="about-img" className="h-96 w-full object-cover" />
        <div className="absolute inset-0 flex font-poppins flex-col justify-center items-center text-center bg-black bg-opacity-20 text-white mx-auto">
          <p className="lg:text-4xl text-2xl font-medium py-3">About us</p>
          <p className="lg:text-5xl font-semibold md:text-5xl text-4xl">
            Your Trusted Partner
          </p>
          <p className="text-xs p-5 font-medium md:text-base">
            Discover our mission, vision, and values, which guide us in
            providing the best{" "}
            <span className="text-red text-lg font-medium"> OE</span> auto
            parts.
          </p>
        </div>
      </div>
      <div
        className="container mx-auto flex flex-col
       items-start justify-start p-10 text-left w-full"
      >
        <div className="flex items-center justify-between flex-col md:flex-row gap-x-5">
          <div className="flex items-start justify-center gap-3 flex-col text-left w-full font-poppins">
            <p className="text-sm font-normal text-gray-500">Quality</p>
            <p className="text-2xl font-medium lg:text-4xl md:text-3xl text-left">
              Providing Reliable Auto Parts for Every Need
            </p>
            <p className="text-sm w-80 lg:w-[80%]">
              At CAP Auto Parts, we are committed to delivering high-quality{" "}
              <span className="text-red font-medium"> OE </span>
              auto parts that meet the needs of our customers. With a wide
              selection of used parts and a focus on reliability, we strive to
              be the go-to destination for all your automotive needs.
            </p>
            <div className="flex items-center gap-10 pt-3">
              <button className=" border-2 border-gray-500 bg-white text-gray-800 text-medium font-light py-2 px-8 rounded-3xl hover:bg-red hover:text-white hover:border-red">
                Learn More
              </button>
              <NavLink to="/contact">Contact Us</NavLink>
            </div>
          </div>
          <div className="mt-8 md:mt-0">
            <img
              src={aboutus}
              alt="about"
              className="w-full h-full rounded-3xl"
            />
          </div>
        </div>
        <div className="pt-10">
          <div className="flex items-center justify-between w-full font-poppins flex-col md:flex-row md:text-left mb-6">
            <p className="text-2xl font-medium lg:text-4xl md:text-3xl text-left 2xl:w-[40%]">
              Delivering Quality Auto Parts & Excellent Service
            </p>
            <p className="text-sm p-2 md:p-0 md:w-[80%] lg:w-[50%] text-left 2xl:w-[40%]">
              We are dedicated to customer satisfaction and high-quality{" "}
              <span className="text-red font-medium">OE</span> auto parts. Our
              expert team is ready to assist with your auto part needs or scrap
              car sales. Contact us today for more information.
            </p>
          </div>
          <div className="mb-6">
            <img
              src={quality}
              alt="quality-img"
              className="w-full h-80 md:h-full rounded-3xl"
            />
          </div>
        </div>
        <div className="flex items-start justify-start flex-col font-poppins py-5">
          <div className="gap-y-1 flex flex-col items-start pb-4">
            <p className="font-medium text-sm">Get to know us</p>
            <p className="text-4xl font-medium">Meet Our Team</p>
            <p className="text-xs font-normal">
              Get to know the key members of our team.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-2">
            {Team.map((member) => (
              <div
                key={member.id}
                className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg p-5 gap-y-1"
              >
                <img
                  src={member.img}
                  alt={member.name}
                  className="w-60 h-60 rounded-2xl mb-4"
                />
                <p className="text-lg font-semibold">{member.name}</p>
                <p className="text-sm font-normal">{member.position}</p>
                <p className="text-xs text-center">{member.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full text-center flex items-center justify-center my-8 flex-col">
          <p className="text-center font-poppins">
            Meet our CEO, <span className="font-bold">Kunju Mohmud</span>. He
            leads <span className="text-red">CAP</span>, a company dedicated to
            providing high-quality{" "}
            <span className="text-red font-medium">OE</span> auto parts.
          </p>
          <div className="mt-2 flex items-center justify-center">
            <img
              src={ceo}
              alt="kunja"
              className="rounded-xl w-32 h-32 mt-5 md:w-40 md:h-40 object-center object-cover"
            />
            <div className="flex justify-center flex-col ml-4 md:ml-10 gap-y-2 font-poppins font-semibold mt-5">
              <p>Kunja</p>
              <p>CEO</p>
            </div>
            <div className="border-l h-14 mx-3 border-gray-500 mt-3"></div>
            <p className="font-poppins font-medium mt-4">
              <span className="text-red">CAP</span> GROUP
            </p>
          </div>
        </div>
        <div className="flex items-start justify-between flex-col md:flex-row font-poppins w-full my-10">
          <div className="flex flex-col gap-3">
            <p className="text-sm font-normal text-gray-500">Quality</p>
            <p className="text-2xl font-semibold">
              Providing Reliable Auto Parts for Over 10 Years
            </p>
            <div className="flex items-end w-full justify-end md:mt-5 md:-ml-10 lg:-ml-0 mb-5 md:mb-0">
              <button className=" border-2 border-gray-500 bg-white text-gray-800 font-medium py-2 px-8 rounded-3xl hover:bg-red hover:text-white hover:border-red">
                Learn More
              </button>
            </div>
          </div>
          <div className="mt-5 md:mt-0">
            <p className="text-xs w-80 lg:w-96 lg:text-sm">
              At our company, we take pride in our long-standing presence in the
              auto parts industry. With over a decade of experience, we have
              successfully processed thousands of cars and sold countless
              high-quality <span className="text-red font-medium"> OE </span>{" "}
              parts to satisfied customers.
            </p>
            <div className="flex items-center text-xs mt-5 gap-2 lg:w-96">
              <div className="flex-col text-left">
                <p className="text-xl font-semibold text-red">50%</p>
                <p>Improvement in Customer Satisfaction</p>
              </div>
              <div className="">
                <p className="text-xl font-semibold text-red">50%</p>
                <p>Reduction in Product Returns</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex item-center justify-center">
        <video width="full" height="full" loop autoPlay muted>
          <source src={logoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default AboutUs;
