import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

function ContactForm() {
  const [state, handleSubmit] = useForm(
    process.env.REACT_APP_FORMSPREE_API_KEY_2
  );

  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [make, setMake] = useState("");
  const [modelName, setModelName] = useState("");
  const [year, setYear] = useState("");
  const [selectedEngineType, setSelectedEngineType] = useState("");
  const [selectedTransmissionType, setSelectedTransmissionType] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccess(true);
      setMessage("Thanks for your submission!");
      setName("");
      setMobile("");
      setEmail("");
      setMake("");
      setModelName("");
      setYear("");
      setSelectedEngineType("");
      setSelectedTransmissionType("");
      setAdditionalInfo("");
      setTimeout(() => setShowSuccess(false), 3000);
    }
  }, [state.succeeded]);

  return (
    <form
      onSubmit={handleSubmit}
      className="p-4 md:p-6 lg:p-8 mt-5 font-poppins"
    >
      <div className="mb-10 flex items-center justify-center gap-x-5 font-poppins">
        <div className="">
          {" "}
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
            className="w-full p-2 border-b-2 border-gray-300 rounded-md text-sm md:text-base lg:text-lg placeholder:text-xs md:placeholder-text-sm lg:placeholder-text-lg placeholder:font-light"
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </div>
        <div className="">
          <input
            type="tel"
            name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            placeholder="Mobile Number"
            className="w-full p-2 border-b-2 border-gray-300 rounded-md text-sm md:text-base lg:text-lg placeholder:text-xs md:placeholder-text-sm lg:placeholder-text-lg placeholder:font-light"
          />

          <ValidationError
            prefix="Mobile"
            field="mobile"
            errors={state.errors}
          />
        </div>
        <div className="">
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            className="w-full p-2 border-b-2 border-gray-300 rounded-md text-sm md:text-base lg:text-lg placeholder:text-xs md:placeholder-text-sm lg:placeholder-text-lg placeholder:font-light"
          />

          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </div>
      </div>

      <div className="mb-4 flex items-center justify-center gap-x-4 font-poppins">
        <div className="">
          <input
            type="text"
            name="make"
            value={make}
            onChange={(e) => setMake(e.target.value)}
            placeholder="Car Name"
            className="w-full p-2 border-b-2 border-gray-300 rounded-md text-sm md:text-base lg:text-lg placeholder:text-xs md:placeholder-text-sm lg:placeholder-text-lg placeholder:font-light"
          />

          <ValidationError prefix="Make" field="make" errors={state.errors} />
        </div>
        <div className="">
          <input
            type="text"
            name="modelName"
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            placeholder="Car Model"
            className="w-full p-2 border-b-2 border-gray-300 rounded-md text-sm md:text-base lg:text-lg placeholder:text-xs md:placeholder-text-sm lg:placeholder-text-lg placeholder:font-light"
          />
          <ValidationError
            prefix="ModelName"
            field="modelName"
            errors={state.errors}
          />
        </div>
        <div className="">
          <input
            type="number"
            name="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="Car Year"
            className="w-full p-2 border-b-2 border-gray-300 rounded-md text-sm md:text-base lg:text-lg placeholder:text-xs md:placeholder-text-sm lg:placeholder-text-lg placeholder:font-light"
          />
          <ValidationError prefix="Year" field="year" errors={state.errors} />
        </div>
      </div>

      <div className="flex py-5 flex-col items-start gap-y-5">
        <fieldset className="font-poppins py-5 flex flex-col items-center">
          <legend className="text-xl text-left">Transmission Type</legend>
          <div className="flex items-start justify-center gap-x-5">
            {["manual", "automatic"].map((type) => (
              <div key={type} className="flex items-start">
                <input
                  type="radio"
                  id={type}
                  name="transmissionType"
                  value={type}
                  className="opacity-0 absolute h-0 w-0"
                  checked={selectedTransmissionType === type}
                  onChange={(e) => setSelectedTransmissionType(e.target.value)}
                />
                <label
                  htmlFor={type}
                  className={`inline-flex items-center cursor-pointer ${
                    selectedTransmissionType === type ? "bg-red-500" : ""
                  }`}
                >
                  <span
                    className={`w-6 h-6 md:w-8 md:h-8 inline-block rounded-lg border-2 ${
                      selectedTransmissionType === type
                        ? "bg-red"
                        : "border-gray-400"
                    }`}
                  ></span>
                  <span className="ml-2 text-xs md:text-sm lg:text-base">
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <fieldset className="font-poppins py-4 flex flex-col items-start">
          <legend className="text-xl text-left">Engine Type</legend>
          <div className="flex items-center justify-center gap-x-5">
            {["petrol", "diesel", "hybrids"].map((type) => (
              <div key={type} className="flex items-center">
                <input
                  type="radio"
                  id={type}
                  name="engineType"
                  value={type}
                  className="opacity-0 absolute h-0 w-0"
                  checked={selectedEngineType === type}
                  onChange={(e) => setSelectedEngineType(e.target.value)}
                />
                <label
                  htmlFor={type}
                  className={`inline-flex items-center cursor-pointer ${
                    selectedEngineType === type ? "bg-red-500" : ""
                  }`}
                >
                  <span
                    className={`w-6 h-6 md:w-8 md:h-8 inline-block rounded-lg border-2 ${
                      selectedEngineType === type ? "bg-red" : "border-gray-400"
                    }`}
                  ></span>
                  <span className="ml-2 text-xs md:text-sm lg:text-base">
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </span>
                </label>
              </div>
            ))}
          </div>
          <ValidationError
            prefix="EngineType"
            field="engineType"
            errors={state.errors}
          />
        </fieldset>
      </div>

      <label className="block font-medium text-gray-700 text-left font-poppins text-xl pb-9">
        Additional Information
        <textarea
          name="additionalInfo"
          rows="8"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          placeholder="Enter any additional information here..."
          className="mt-5 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-opacity-50 placeholder:pt-5 placeholder:pl-5 placeholder:text-sm md:placeholder:text-lg lg:placeholder:text-2xl placeholder:font-medium text-sm md:text-base lg:text-lg p-2 placeholder:font-poppins font-poppins focus:placeholder:opacity-0 focus:outline-none focus:ring-2 focus:ring-red focus:border-red"
        ></textarea>
      </label>
      <ValidationError
        prefix="AdditionalInfo"
        field="additionalInfo"
        errors={state.errors}
      />

      <button
        type="submit"
        disabled={state.submitting}
        className="py-3 px-20 bg-red text-white rounded-full font-semibold hover:bg-white hover:text-red border-2 border-red mt-5 font-poppins text-base md:text-lg lg:text-xl"
      >
        Submit
      </button>
      {showSuccess && <p className="text-green-500 text-sm pt-5">{message}</p>}
    </form>
  );
}

export default ContactForm;
