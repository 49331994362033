import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoCloseCircleOutline } from "react-icons/io5";

import banner from "./assets/buyparts.webp";
import loader from "./assets/loader.gif";

const BuyAutoParts = () => {
  const [autoParts, setAutoParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchAutoParts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          "https://coimbatoreautoparts-5c3e0ad15504.herokuapp.com/api/v1/cars"
        );
        let data = response.data.cars;
        if (Array.isArray(data)) {
          data = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAutoParts(data);
        } else {
          console.error("Expected an array but received:", data);
          setAutoParts([]);
        }
      } catch (error) {
        console.error("Error fetching auto parts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAutoParts();
  }, []);

  const toggleModal = (car = null) => {
    setSelectedCar(car);
  };

  useEffect(() => {
    const closeModalOnOutsideClick = (e) => {
      if (e.target.id === "modalBackdrop") {
        toggleModal();
      }
    };

    window.addEventListener("click", closeModalOnOutsideClick);
    return () => {
      window.removeEventListener("click", closeModalOnOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="relative h-96 font-poppins">
        <img src={banner} alt="main" className="w-full h-96 object-cover" />
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center bg-black bg-opacity-50 text-white mx-auto">
          <p className="text-sm text-gray-300 font-semibold pb-3">
            Buy from us
          </p>
          <h1 className="lg:text-6xl font-bold md:text-3xl pb-2 text-2xl">
            Buy Auto Parts
          </h1>
          <p className="text-sm p-5 font-light lg:w-[50%] md:w-[70%]">
            Find quality auto-parts for your vehicle at affordable prices.
            Browse our wide selection of auto-parts and choose the perfect fit
            for your vehicle. We offer competitive prices and high-quality{" "}
            <span className="text-red">OE</span> products.
          </p>
        </div>
      </div>
      <div className="container mx-auto my-10 font-poppins">
        <h2 className="text-2xl font-bold mb-5 text-center underlines">
          Our Products
        </h2>
        {isLoading ? (
          <img src={loader} alt="loader" className="mx-auto" />
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {Array.isArray(autoParts) &&
              autoParts.map((car) => (
                <div
                  key={car._id}
                  className="flex flex-col bg-white shadow-md rounded-lg overflow-hidden m-4 font-poppins font-medium cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
                  onClick={() => toggleModal(car)} // Open modal with car data
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src={`https://coimbatoreautoparts-5c3e0ad15504.herokuapp.com/api/v1/cars/${car.id}/images/0`}
                      alt={`${car._id}`}
                    />
                  </div>
                  <div className="p-6 text-sm">
                    <div className="uppercase tracking-wide text-sm text-black font-semibold">
                      <p>{car.make}</p>
                      <p>{car.model}</p>
                    </div>
                  </div>
                </div>
              ))}

            {selectedCar && (
              <div
                id="modalBackdrop"
                className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center font-poppins"
              >
                <div className="relative bg-gray-500 text-white p-5 rounded-lg shadow-lg max-w-sm mx-4">
                  <button
                    className="absolute top-0 right-0 mt-2 mr-2 px-4 py-2 text-white"
                    onClick={() => toggleModal()}
                  >
                    <IoCloseCircleOutline
                      size={24}
                      className="hover:text-black"
                    />
                  </button>
                  <h2 className="font-bold text-lg mb-4">
                    {selectedCar.make} {selectedCar.model}
                  </h2>
                  <div className="mx-2 mb-4">
                    <Slider {...sliderSettings}>
                      {[...Array(5).keys()].map((index) => (
                        <div key={index} className="px-1">
                          <img
                            className="w-full h-auto object-contain max-h-60 mx-auto"
                            src={`https://coimbatoreautoparts-5c3e0ad15504.herokuapp.com/api/v1/cars/${selectedCar.id}/images/${index}`}
                            alt={`${selectedCar._id} - ${index}`}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <p className="text-sm">
                    <span className="font-semibold">Year:</span>{" "}
                    {selectedCar.year}
                  </p>
                  <p className="text-sm">
                    <span className="font-semibold">Variant:</span>{" "}
                    {selectedCar.variant ? "petrol" : "diesel"}
                  </p>
                  <p className="text-sm">
                    <span className="font-semibold">Transmission:</span>{" "}
                    {selectedCar.manual ? "manual" : "automatic"}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BuyAutoParts;
