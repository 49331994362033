const customer = [
  {
    name: "Saim Khan",
    star: 5,
    description:
      "Ordered and received four original alloys with wheel caps for pajero sfx at a reasonable price.Pre sales interaction was good and they are approachable & helpful even in after sales. Good luck & Thanks. Nice experience dealing with them.",
    city: "Chalakkudi",
    state: "Kerala",
  },
  {
    name: "Saran",
    star: 4.5,
    description:
      "Polo 1.6 gear box I searched a lot of places I didn't get this rare item and i saw coimbatore auto parts instagram page then I contacted them and finally found it this rare gear box with their help, very reliable place, reliable quality! Thank you for help ❤️",
    city: "Kochi",
    state: "Kerala",
  },
  {
    name: "Antony Ajith",
    star: 4,
    description:
      "Guys, its an honest review... One of the best store for used car parts is Coimbatore auto parts on my own experience. I have ordered a used car part and it doesn't fit to my car. When i ask for a refund, they did it easily the most trust worthy store for used car parts.",
    city: "Bangalore",
    state: "Karnataka",
  },
  {
    name: "Pradeesh Senthil",
    star: 4.7,
    description:
      "I had a great experience with them they helped me in a tough situation for my car with there help I was able to recover my car so soon from the garage they treat their customers in a genuine and gentle way trust me They are the best in coimbatore for second parts and cars",
    city: "Coimbatore",
    state: "Tamil Nadu",
  },
  {
    name: "Guna Sekaran",
    star: 5,
    description:
      "Recently Coimbatore Auto Auto parts has supplied full set of electronic and hardwares for my Honda City. I ordered the spares from Chennai. Thier service was very well and prompt delivery. Thier parts are geniune and providing warrenty . Liked CAP service. Keep up good work. Raja Sekaran Chennai",
    city: "Chennai",
    state: "Tamil Nadu",
  },
  {
    name: "Sai Kumar",
    star: 4.5,
    description:
      "I have ordered a intercooler boost pipe for my car and he sent it in a day and packed nicely.. spare was same as he shown in the picture.. he has not reduced the price though.. good buying experience.",
    city: "Hyderabad",
    state: "Andhra",
  },
];
export default customer;
