import faizal from "../assets/faizal.webp";
import jai from "../assets/jai.webp";
import sugial from "../assets/sugil.webp";
import muthar from "../assets/cto.webp";
import nasar from "../assets/nasar.webp";
import ranjith from "../assets/ranjith.webp";
import sarathi from "../assets/sarathi.webp";
import santhosh from "../assets/santhosh.webp";

const Team = [
  {
    id: 1,
    name: "Faizal Rahman",
    position: "Project Lead",
    description:
      "Guides project strategy and execution, leveraging a decade of industry insight.",
    img: faizal,
  },
  {
    id: 2,
    name: "Jailani",
    position: "Operations Manager",
    description:
      "Ensures operational excellence and team productivity, with a strong focus on efficiency.",
    img: jai,
  },
  {
    id: 3,
    name: "Suhail",
    position: "Customer Relations Manager",
    description:
      "Drives customer satisfaction and engagement through innovative service strategies.",
    img: sugial,
  },
  {
    id: 4,
    name: "Muthar",
    position: "Chief Technology Officer",
    description:
      "Shapes the company's technological vision and leads its implementation, backed by extensive experience.",
    img: muthar,
  },
  {
    id: 5,
    name: "Nasar",
    position: "Senior Engineer",
    description:
      "Leads complex projects with expertise in engineering solutions, ensuring quality and innovation.",
    img: nasar,
  },
  {
    id: 6,
    name: "Santhosh",
    position: "Engineer",
    description:
      "Specializes in design and development, bringing fresh perspectives to auto parts innovation.",
    img: santhosh,
  },
  {
    id: 7,
    name: "Sarathi",
    position: "Engineer",
    description:
      "Focuses on product testing and quality assurance, ensuring high standards are met consistently.",
    img: sarathi,
  },
  {
    id: 8,
    name: "Ranjith",
    position: "Helper",
    description:
      "Supports the engineering team with essential tasks, contributing to project efficiency and success.",
    img: ranjith,
  },
];

export default Team;
