import React, { useState } from "react";

const PhoneNumbersModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleBackdropClick = () => {
    onClose();
  };

  const handleModalContentClick = (event) => {
    event.stopPropagation(); // Prevent click from propagating to the backdrop
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-white p-5 rounded-lg shadow-lg"
        onClick={handleModalContentClick}
      >
        <div className="flex justify-end">
          <button onClick={onClose} className="text-black">
            &times;
          </button>
        </div>
        <div className="flex items-center justify-center flex-col text-red font-poppins">
          <p className="text-lg">Contact Us:</p>
          <p>Phone 1: (+91) 90477-77215</p>
          <p>Phone 2: (+91) 90477-77216</p>
        </div>
      </div>
    </div>
  );
};

const Help = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <div>
      <button className="text-gray-500 pr-5" onClick={toggleModal}>
        Help Center
      </button>
      <PhoneNumbersModal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

export default Help;
