import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

import whatsapp from "./assets/whatsapp.webp";
import mail from "./assets/mail.webp";
import mobile from "./assets/phone.webp";
import place from "./assets/place.webp";
import { answers } from "../SellScrapCars/components/sell";

const ContactUs = () => {
  const [state, handleSubmit] = useForm(
    process.env.REACT_APP_FORMSPREE_API_KEY
  );

  const [showSuccess, setShowSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiry, setInquiry] = useState("");

  useEffect(() => {
    if (state.succeeded) {
      setShowSuccess(true);
      setMessage("Thanks for your submission!");
      setName("");
      setPhone("");
      setInquiry("");
      setTimeout(() => setShowSuccess(false), 3000);
    }
  }, [state.succeeded]);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleInquiry = (e) => {
    setInquiry(e.target.value);
  };

  return (
    <>
      {" "}
      <div className="py-10 container flex items-center justify-center flex-col w-full mx-auto">
        <div className="text-center font-poppins">
          <p className="font-normal text-xl md:text-3xl pb-2">Get in Touch</p>
          <p className="text-xs text-gray-400 pb-3">
            Have Questions? Need Assistance? We’re here to help!
          </p>
        </div>
        <div className="flex items-center justify-around flex-col w-full lg:flex-row pt-3">
          <div className="pb-5 p-2 flex items-center justify-center gap-x-10">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center justify-center gap-y-5"
            >
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={handleName}
                className="w-full md:w-[50vh] p-3 border-none rounded-md shadow-lg"
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={phone}
                onChange={handlePhone}
                className="w-full md:w-[50vh] p-3 border-none rounded-md shadow-lg"
              />
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
              <textarea
                name="inquiry"
                value={inquiry}
                onChange={handleInquiry}
                placeholder="Type your Inquiry"
                className="w-full md:w-[50vh] p-3 border-none rounded-md shadow-lg"
              ></textarea>
              <ValidationError
                prefix="Inquiry"
                field="inquiry"
                errors={state.errors}
              />
              <button
                type="submit"
                disabled={state.submitting}
                className="py-3 px-20 bg-red text-white rounded-full shadow-lg"
              >
                Contact Us
              </button>
              {showSuccess && (
                <p className="text-green-500 text-sm">{message}</p>
              )}
            </form>
            <hr className="w-0.5 h-80 bg-gray-200 hidden lg:flex" />
          </div>
          <hr className="w-80 h-0.5 lg:hidden bg-gray-200 md:w-[60vh] my-5" />
          <div className="font-poppins flex flex-col items-center justify-center gap-y-2 pb-10">
            <img src={whatsapp} alt="whatsapp" className="w-40 h-40" />
            <p className=" text-xl md:text-2xl lg:text-4xl font-semibold">
              WhatsApp Us
            </p>
            <p className="text-sm font-normal text-gray-400">
              Directly connect with us on WhatsApp
            </p>
            <a
              href="https://wa.me/919047556556"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="px-8 py-3 bg-green-600 text-white rounded-full text-sm font-medium">
                Chat on Whatsapp
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full font-poppins px-5 mx-auto">
        <div className="flex items-start justify-start text-left flex-col lg:pl-20">
          <p className="text-sm text-gray-400 pb-2">Contact Us</p>
          <p className="pb-2 text-2xl lg:text-4xl font-normal">
            Contact Information
          </p>
          <p className="text-xs text-gray-400">
            For any inquiries, please feel free to reach to us.
          </p>
        </div>
        <div className="flex md:items-center justify-between flex-col md:flex-row mt-5 lg:pl-20 pb-5 md:pb-10">
          <div className="pb-4 md:pb-0">
            <div className="pb-4">
              <div className="">
                <img src={mail} alt="mail" className="w-6 h-6" />
                <p className="font-semibold text-base pb-2">Email</p>
              </div>
              <div className="pb-3">
                <p className="text-xs font-medium pb-1">Send us a email</p>
                <a
                  href="mailto:coimbatoreautopartsdm@gmail.com"
                  className="underline text-xs font-medium"
                >
                  <span className="text-red">coimbatoreautopartsdm</span>
                  @gmail.com
                </a>
              </div>
            </div>
            <div className="pb-4">
              <div className="">
                <img src={mobile} alt="phone" className="w-8 h-8" />
                <p className="font-semibold text-base pb-2">Phone</p>
              </div>
              <div className="flex flex-col items-start">
                <p className="text-xs font-medium pb-1">Call us at</p>
                <a href="tel:+91-9944994421" className="text-xs font-medium">
                  +91 <span className="text-red">9944994421</span>
                </a>
                <a href="tel:+91-9047556556" className="text-xs font-medium">
                  +91 <span className="text-red">9047556556</span>
                </a>
              </div>
            </div>
            <div className="pb-4">
              <div className="">
                <img src={place} alt="location" className="w-7 h-10" />
                <p className="font-semibold text-base pb-2">Office</p>
              </div>
              <div className="">
                <p className="text-xs font-medium pb-1">
                  13/1, opposite breeze bakery, Dhal Factory Street, Palakadu
                  Road, Kuniyamuthur, <br /> Tamil Nadu 641008
                </p>
              </div>
            </div>
          </div>
          <div className="w-full h-full flex items-center justify-center mx-auto">
            <p className="w-full mx-auto">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15668.14053849109!2d76.953689!3d10.9607186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8591cee8eaaab%3A0x669930893c1b6e5!2sCoimbatore%20Auto%20Parts!5e0!3m2!1sen!2sin!4v1718956452227!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
                className="h-[40vh] md:h-[30vh] lg:h-[45vh] md:w-[80%] mx-auto rounded-3xl shadow-lg"
              ></iframe>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-5 lg:px-20 items-start justify-around lg:flex-row font-poppins py-10">
        <div className="mt-5 flex flex-col items-start gap-y-4">
          <p className="text-sm text-gray-400">Convenience</p>
          <p className="text-2xl md:text-4xl lg:text-5xl">
            Frequently Asked Questions
          </p>
          <p className="text-base">
            Find answers to common questions about buying auto-parts.
          </p>
          <button className="py-3 px-16 bg-white text-black hover:bg-red hover:text-white border-red border-2 rounded-full shadow-lg my-5">
            Contact Us
          </button>
        </div>
        <div className="">
          {answers.map((answer) => (
            <div
              key={answer.id}
              className="flex flex-col items-start gap-y-3 p-5 border-b-2 font-poppins border-gray-300"
            >
              <p className="text-lg font-normal">{answer.title}</p>
              <p className="text-xs font-light">{answer.description}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContactUs;
