import React from "react";
import { NavLink } from "react-router-dom";

import save from "./assests/save.webp";
import money from "./assests/money.webp";

const Quality = () => {
  return (
    <>
      <div className="flex items-center justify-center">
        <img
          src={save}
          alt="save"
          className="mx-auto lg:w-full absolute min-h-screen"
        />
        <div className="relative flex items-center flex-col md:flex-row justify-around w-full min-h-screen md:mt-80 lg:mt-0">
          <div className="flex flex-col lg:items-start lg:justify-start font-poppins">
            <div className="flex flex-col text-left items-start justify-start pl-5">
              <p className="text-lg text-gray-600 pb-3 font-medium xl:text-2xl">
                Quality
              </p>
              <p className="text-4xl font-medium pb-3">
                Save Money and the <br /> Enviroment with Us
              </p>
              <p className="text-left text-sm text-gray-600 lg:text-xl font-medium">
                At our used auto-parts selling company, we offer a wide <br />{" "}
                range of benefits for our customers. By choosing our <br />{" "}
                services, you can enjoy cost savings and contribute to a <br />{" "}
                greener environment.
              </p>
            </div>
            <div className="flex-col lg:flex-row flex lg:items-center lg:gap-10 pl-5 items-start">
              <div className="py-5">
                <p className="text-lg text-gray-900 font-semibold">
                  Cost Savings
                </p>
                <p className="w-60 text-xs text-gray-600 font-medium">
                  Our high-quality used auto-parts are affordable, helping you
                  save money on repairs.
                </p>
              </div>
              <div className="">
                <p className="text-lg text-gray-900 font-semibold">
                  Environmental Impact
                </p>
                <p className="w-60 text-xs text-gray-600 font-medium">
                  By reusing auto-parts, you contribute to reducing waste and
                  conserving resources.
                </p>
              </div>
            </div>
            <div className="flex items-center lg:justify-center pl-5 gap-x-5 py-4">
              <NavLink
                to="/about"
                className="px-10 py-2 border-2 text-xs border-gray-500 bg-white text-gray-800 hover:bg-red hover:text-white rounded-3xl"
              >
                Learn More
              </NavLink>
              <NavLink to="/contact" className="md:text-xs">
                Contact Us
              </NavLink>
            </div>
          </div>
          <div className="">
            <img
              src={money}
              alt="money"
              className="lg:w-full h-full xl:h-[100vh] 2xl:h-[80vh] object-cover object-center"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Quality;
