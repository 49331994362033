import form from "../assets/01-Fill-Form.gif";
import quote from "../assets/02-Get-Quote.gif";
import paid from "../assets/03-Get-Paid.gif";

const Sell = [
  {
    id: 1,
    title: "Get Cash for Your Scrap Car in Three Simple Steps",
    description: "Fill out our online form with details about your scrap car.",
    img: form,
  },
  {
    id: 2,
    title: "Receive an Instant Quote for Your Scrap Car",
    description:
      "Our team will evaluate your car and provide you with a fair and competitive quote.",
    img: quote,
  },
  {
    id: 3,
    title: "Get Paid and Schedule a Convenient Pickup Time",
    description:
      "Once you accept the quote, we'll arrange for payment and pick up your car at a time that suits you.",
    img: paid,
  },
];

const answers = [
  {
    id: 1,
    title: "How to place an order?",
    description:
      "To place an order, simply browse our inventory, select the desired auto part, and proceed to checkout.",
  },
  {
    id: 2,
    title: "What payment methods do you accept?",
    description:
      "We accept various payment methods, including credit cards, UPI, PayPal, and bank transfers.",
  },
  {
    id: 3,
    title: "Do you offer a warranty?",
    description:
      "Yes, we offer a warranty on all our auto parts. Please refer to our warranty policy for more information.",
  },
  {
    id: 4,
    title: "Can I return a part?",
    description:
      "Yes, you can return a part within 10 days of purchase. Please review our return policy for details.",
  },
  {
    id: 5,
    title: "How long does shipping take?",
    description:
      "Shipping times may vary depending on your location. Please contact our customer support for estimated delivery times.",
  },
];

export { Sell, answers };
