import React from "react";
import { NavLink } from "react-router-dom";

import logo from "./logo.png";
import Map from "./Model/Map";
import Help from "./Model/Help";
import Quality from "./Model/Quality";
import Complaints from "./Model/Compalints";
import FAQ from "./Model/FAQ";
import FeedBack from "./Model/FeedBack";
import CustomerTermsModal from "./Model/CustomersTermsModal";
import SellerTermsModal from "./Model/SellerTermsModal";
import PrivacyModal from "./Model/PrivacyModal";
import LegalModal from "./Model/LegalModal";

const Footer = () => {
  return (
    <div className="bg-black text-white">
      <div className="container mx-auto p-4">
        <img
          src={logo}
          alt="logo"
          className="w-80 pl-5 h-9 my-5 lg:h-12 lg:pl-0 ml-3"
        />

        <div className="flex items-center justify-between p-5 flex-col md:flex-row">
          <div className="flex flex-col justify-center">
            <div className="flex items-center justify-center gap-x-20 font-poppins">
              <ul className="font-medium text-xs gap-y-3 flex flex-col">
                <li>Quick Links</li>
                <NavLink to="/sell-scrap-cars" className=" text-gray-500">
                  Sell your Scrap Car
                </NavLink>
                <NavLink to="/buy-auto-parts" className=" text-gray-500">
                  Buy Auto-parts
                </NavLink>
                <Map />
                <Help />
              </ul>
              <ul className="font-medium text-xs gap-y-3 flex flex-col">
                <li>How We Work</li>
                <Quality />
                <Complaints />
                <FAQ />
                <FeedBack />
              </ul>
            </div>
          </div>
          <div className="text-xs font-poppins flex flex-col justify-center gap-y-3 pt-6">
            <p>Subscribe to our Newsletter, get products related info.</p>
            <input
              type="email"
              placeholder="Enter your email"
              className="bg-gray-200 p-2 rounded-3xl"
            />
            <button className="bg-blue-500 text-white p-2 rounded-3xl">
              Subscribe
            </button>
            <p>No Spamming. only Professional.</p>
          </div>
        </div>
        <hr className="w-full h-1 text-gray-200 my-3 lg:mb-1" />
        <div className="flex items-center justify-between font-poppins text-xs flex-col gap-y-2 lg:flex-row lg:p-5 mb-4">
          <div className="flex items-center gap-3 font-extralight">
            <CustomerTermsModal />
            <SellerTermsModal />
            <PrivacyModal />
            <LegalModal />
          </div>
          <div className="">
            <a href="https://ansaribrahim.me/">
              ©2024 Site. All rights reserved. crafted by{" "}
              <span className="text-red">Ansar Ibrahim</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
