import React, { useState } from "react";

const MapModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  // Function to stop propagation for modal content clicks
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-5"
      onClick={onClose} // Close modal when backdrop is clicked
    >
      <div
        className="bg-white p-5 rounded-lg shadow-lg max-w-3xl w-full h-[50vh] overflow-hidden relative"
        onClick={stopPropagation} // Prevent clicks from closing the modal
      >
        <button
          onClick={onClose}
          className="float-right text-xl font-semibold"
          style={{ color: "black" }}
        >
          &times;
        </button>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15668.14053849109!2d76.953689!3d10.9607186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8591cee8eaaab%3A0x669930893c1b6e5!2sCoimbatore%20Auto%20Parts!5e0!3m2!1sen!2sin!4v1718956452227!5m2!1sen!2sin"
          width="600"
          height="600"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
          className="w-full h-full"
        ></iframe>
      </div>
    </div>
  );
};

const Map = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button className="text-gray-500 pr-5" onClick={() => setModalOpen(true)}>
        Store Locations
      </button>

      <MapModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default Map;
