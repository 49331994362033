import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import logo from "./logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("/");
  const location = useLocation();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const controls = useAnimation();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  useEffect(() => {
    controls.start((i) => ({
      scale: [1, 1.2, 1, 1.2, 1],
      transition: {
        repeat: Infinity,
        duration: 10,
        ease: "easeInOut",
        times: [0, 0.25, 0.5, 0.75, 1],
      },
    }));
  }, [controls]);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="bg-black shadow font-poppins text-sm fixed top-0 w-full z-50">
      <div className="flex flex-col container mx-auto lg:flex-row lg:items-center lg:justify-between p-5 w-full">
        <div className="flex justify-between items-center">
          <NavLink to="/">
            <motion.img
              src={logo}
              alt="logo"
              className="h-8 w-auto sm:h-10"
              animate={controls}
              whileHover={{ scale: 1.2 }}
              onClick={() => {
                setActiveTab("/");
                setIsOpen(false);
              }}
            />
          </NavLink>
          <div className="lg:hidden pt-5">
            <button
              type="button"
              className="text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="w-6 h-6 relative">
                <motion.span
                  className="block w-full h-1 absolute bg-current transform transition rounded-full text-white"
                  initial={false}
                  animate={isOpen ? { rotate: 45, y: 0 } : { rotate: 0, y: 0 }}
                  style={{ originX: "center", originY: "center" }}
                />
                <motion.span
                  className="block w-full h-1 absolute bg-current transform transition text-red rounded-full"
                  initial={false}
                  animate={
                    isOpen ? { rotate: -45, y: -12 } : { rotate: 0, y: 0 }
                  }
                  style={{ originX: "center", originY: "center", top: "50%" }}
                />
              </div>
            </button>
          </div>
        </div>
        <div className="lg:flex">
          {(isOpen || isLargeScreen) && (
            <div className="overflow-hidden font-semibold lg:overflow-visible text-white items-center text-left justify-center flex-col flex text-2xl lg:text-sm min-h-screen lg:min-h-0">
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="flex-col flex lg:flex-row cursor-pointer lg:items-center lg:justify-center lg:space-x-10 lg:space-y-0 space-y-5 space-x-0 items-center justify-center text-2xl lg:text-xs xl:text-sm 2xl:text-base min-h-screen lg:min-h-0 bg-black lg:bg-transparent lg:text-white lg:font-semibold lg:font-poppins"
              >
                <NavLink
                  to="/"
                  className={`block mt-4 md:inline-block md:mt-0 hover:text-red md:mx-6 ${
                    activeTab === "/" ? "text-red" : ""
                  }`}
                  onClick={() => setIsOpen(false)}
                >
                  Home
                </NavLink>

                <NavLink
                  to="/about"
                  onClick={() => setIsOpen(false)}
                  className={`block mt-4 md:inline-block md:mt-0 hover:text-red md:mx-6 ${
                    activeTab === "/about" ? "text-red" : ""
                  }`}
                >
                  About Us
                </NavLink>
                <NavLink
                  to="/services"
                  onClick={() => setIsOpen(false)}
                  className={`block mt-4 md:inline-block md:mt-0 hover:text-red md:mx-6 ${
                    activeTab === "/services" ? "text-red" : ""
                  }`}
                >
                  Services
                </NavLink>
                <NavLink
                  to="/buy-auto-parts"
                  onClick={() => setIsOpen(false)}
                  className={`block mt-4 md:inline-block md:mt-0 hover:text-red md:mx-6 ${
                    activeTab === "/buy-auto-parts" ? "text-red" : ""
                  }`}
                >
                  Buy Auto Parts
                </NavLink>
                <NavLink
                  to="/sell-scrap-cars"
                  onClick={() => setIsOpen(false)}
                  className={`block mt-4 md:inline-block md:mt-0 hover:text-red md:mx-6 ${
                    activeTab === "/sell-scrap-cars" ? "text-red" : ""
                  }`}
                >
                  Sell Scrap Cars
                </NavLink>

                <NavLink
                  to="/contact"
                  onClick={() => setIsOpen(false)}
                  className="block mt-4 md:inline-block md:mt-0 hover:text-red hover:bg-white md:mx-6 bg-red text-white px-4 text-xs py-2 rounded-3xl"
                >
                  Contact Us
                </NavLink>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
