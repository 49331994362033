import React, { useState, useRef, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

const Complaints = () => {
  const [state, handleSubmit] = useForm(
    process.env.REACT_APP_FORMSPREE_API_KEY_4
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalContentRef = useRef(null);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    if (state.succeeded) {
      setTimeout(() => {
        setIsModalOpen(false);
      }, 3000);
    }
  }, [state.succeeded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div>
      <button onClick={toggleModal} className="text-gray-500">
        Complaints
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex z-50 justify-center items-center">
          <div
            className="bg-white p-5 rounded-lg shadow-lg relative w-96"
            ref={modalContentRef}
          >
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="name"
                required
                placeholder="Your Name"
                className="mt-1 p-2 w-full border rounded-md text-black"
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
              <input
                type="tel"
                name="phone"
                required
                placeholder="Your Phone"
                className="mt-1 p-2 w-full border rounded-md text-black"
              />
              <ValidationError
                prefix="Phone"
                field="phone"
                errors={state.errors}
              />
              <textarea
                name="complaint"
                required
                placeholder="Your Complaint"
                rows="4"
                className="mt-1 p-2 w-full border rounded-md text-black"
              ></textarea>
              <ValidationError
                prefix="Complaint"
                field="complaint"
                errors={state.errors}
              />
              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md"
                >
                  {state.submitting ? "Submitting..." : "Submit"}
                </button>
                <button
                  type="button"
                  onClick={toggleModal}
                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Close
                </button>
              </div>
              {state.succeeded && (
                <p className="text-green-500">Thanks for your complaint!</p>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Complaints;
