import buy from "./buy.gif";
import sell from "./sell.gif";
import free from "./free.gif";

const service = [
  {
    img: buy,
    title: "We Buy Scrap Cars",
    description:
      "We offer competitive rates for your scrap cars, ensuring you get the best value. Our process is transparent, quick, and we provide free pickup service within a 100 km radius.",
  },
  {
    img: sell,
    title: "We Sell Auto-Parts",
    description:
      "Explore our extensive selection of high-quality auto-parts. Catering to a wide range of vehicles, we ensure you find the perfect part to enhance your vehicle's performance and longevity.",
  },
  {
    img: free,
    title: "Free Car Pickup",
    description:
      "Planning to sell your scrap car? We make it easy with our free car pickup service, available within a 100 km radius. Experience convenience and value right at your doorstep.",
  },
];

export default service;
