import breake from "./assests/down.gif";
import engine from "./assests/Icon-01---Engine.gif";
import gear from "./assests/gear.gif";
import alloy from "./assests/alloy.gif";
import tire from "./assests/tyre.gif";
import suspense from "./assests/supension.gif";
import body from "./assests/body.gif";
import inter from "./assests/interior.gif";
import elect from "./assests/electrical.gif";
import safe from "./assests/safty.gif";
import free from "./assests/free.gif";
import sell from "./assests/sell.gif";
import qual from "./assests/qual.gif";

const card = [
  {
    title: "Engine",
    image: engine,
  },
  {
    title: "Gearbox",
    image: gear,
  },
  {
    title: "Alloy Wheels",
    image: alloy,
  },
  {
    title: "Tires",
    image: tire,
  },
  {
    title: "Brakes",
    image: breake,
  },
  {
    title: "Suspension",
    image: suspense,
  },
  {
    title: "Body",
    image: body,
  },
  {
    title: "Interior",
    image: inter,
  },
  {
    title: "Electrical",
    image: elect,
  },
  {
    title: "Safety",
    image: safe,
  },
];

const whatWeDo = [
  {
    image: sell,
    title: "Sell Auto-Parts",
    description:
      "We offer a wide range of used auto-parts for various car models.",
  },
  {
    image: free,
    title: "Free Car Pickup",
    description: "We Provide free car pickup service for your convenience.",
  },
  {
    image: qual,
    title: "Quality Auto-Parts",
    description:
      "We ensure that all our auto-parts are of high quality and in good condition.",
  },
];

export { card, whatWeDo };
