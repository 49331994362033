import React, { useState, useRef, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

const FeedBack = () => {
  const [state, formSubmit] = useForm(
    process.env.REACT_APP_FORMSPREE_API_KEY_5
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Custom submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    await formSubmit(e);
  };

  // Automatically close the modal on successful submission
  useEffect(() => {
    if (state.succeeded) {
      setIsModalOpen(false);
    }
  }, [state.succeeded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <button onClick={toggleModal} className="text-gray-500">
        Give Feedback
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
          >
            <form onSubmit={handleSubmit} className="w-full max-w-lg">
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="feedback"
                  >
                    Feedback
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="feedback"
                    name="feedback"
                    required
                  ></textarea>
                  <ValidationError
                    prefix="Feedback"
                    field="feedback"
                    errors={state.errors}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  type="submit"
                  disabled={state.submitting}
                >
                  Submit
                </button>
                <button
                  onClick={toggleModal}
                  className="bg-transparent hover:bg-red-500 text-red font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded hover:bg-red"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedBack;
