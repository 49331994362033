import React, { useState, useEffect } from "react";

const Quality = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalContent = [
    "Ensure all components are present.",
    "Verify the functionality of each component.",
    "Check for any physical damage.",
    "Test the product under operational conditions.",
    "Final inspection and approval.",
  ];

  const closeModal = () => setIsModalOpen(false);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.id === "modalBackdrop") {
        closeModal();
      }
    };

    // Add when the modal is open and remove when the modal is closed
    if (isModalOpen) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)} className="text-gray-500">
        Quality Checks
      </button>
      {isModalOpen && (
        <div
          id="modalBackdrop"
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div className="bg-white p-5 rounded-lg shadow-lg text-black">
            <button className="text-lg text-red" onClick={closeModal}>
              &times;
            </button>
            <ul className="list-none p-0 m-0">
              {modalContent.map((point, index) => (
                <li
                  key={index}
                  className="bg-gray-100 my-2 p-2 rounded-md shadow-sm text-gray-800"
                >
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quality;
