import React from "react";
import { NavLink } from "react-router-dom";

import main from "./assets/main.webp";
import sellCar from "./assets/sellCar.gif";
import { Sell, answers } from "./components/sell";
import freesell from "./assets/sell_hassle_free.gif";
import ContactForm from "./components/ContactForm";

const SellScrapCars = () => {
  return (
    <>
      <div className="relative h-96">
        <img src={main} alt="main" className="w-full h-96 object-cover" />
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center bg-black bg-opacity-50 text-white mx-auto font-poppins">
          <p className="text-sm text-gray-300 font-semibold pb-5">
            Ready to Move On? Sell Your Scrap Car Today!
          </p>
          <h1 className="lg:text-6xl font-bold md:text-3xl pb-3 text-2xl">
            Quick, Easy, and Reliable
          </h1>
          <p className="text-sm p-5 font-light lg:w-[50%] md:w-[70%]">
            Turn your scrap car into cash easily. We offer quick, fair
            evaluations and instant payments. Make eco-friendly choices with us
            for a better tomorrow.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-around flex-col md:flex-row w-full font-poppins p-5 py-10">
        <div className="flex items-start flex-col gap-y-3">
          <p className="text-4xl font-normal pb-3">
            Sell your Scrap Car Hassle-Free
          </p>
          <p className="text-xs font-normal w-80 text-gray-400 lg:w-full pb-3">
            Get the best value for your scrap car with our hassle-free selling
            process.
          </p>
          <div className="flex items-center justify-center gap-x-10">
            <a
              href="#contact"
              className="bg-red text-xs px-8 py-3 rounded-full text-white font-semibold hover:bg-white hover:text-red border-2 border-red"
            >
              Sell Now
            </a>
            <NavLink
              to={"/contact"}
              className="hover:text-red text-sm font-medium"
            >
              Contact Us &gt;
            </NavLink>
          </div>
        </div>
        <div className="pt-5 md:pt-0">
          <img src={sellCar} alt="sellcar" className="w-full md:h-80" />
        </div>
      </div>
      <hr />
      <div className="p-5 lg:px-32 flex items-start justify-between 2xl:px-60 flex-col md:flex-row font-poppins py-16">
        <div className="gap-y-2 flex flex-col items-start">
          <p className="text-sm font-medium text-gray-400">Easy</p>
          <p className="text-4xl font-normal">Sell Your Car</p>
        </div>
        <div className="flex flex-col items-start pt-3 md:pt-0 gap-y-3">
          <p className="text-xs md:w-96">
            Get the best price for your scrap car hassle-free. We offer a quick
            and convenient process to sell your car and get paid in no time.
          </p>

          <div className="flex items-center justify-center gap-x-10">
            <a
              href="#contact"
              className="bg-red text-xs px-8 py-3 rounded-full text-white font-semibold hover:bg-white hover:text-red border-2 border-red"
            >
              Sell Now
            </a>
            <NavLink
              to={"/contact"}
              className="hover:text-red text-sm font-medium"
            >
              Contact Us &gt;
            </NavLink>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex items-center justify-center py-10 flex-col font-poppins">
        <p className="md:text-3xl text-lg lg:text-5xl font-normal text-center">
          Discover the Easy Way to Sell Your Scrap Car
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-5">
          {Sell.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center p-5 gap-y-3 text-center font-poppins"
            >
              <img src={item.img} alt={item.title} className="w-60" />
              <p className="text-sm font-medium">{item.title}</p>
              <p className="text-xs text-gray-400 w-80 md:w-60 lg:w-80">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <div className="p-10 flex items-center md:justify-between lg:justify-around font-poppins flex-col md:flex-row">
        <div className="flex-col text-center md:text-left gap-y-2 flex pb-10 md:pb-0">
          <p className=" text-sm text-gray-400 font-normal md:text-lg lg:text-xl">
            Convenience
          </p>
          <p className="text-xl md:text-2xl lg:text-4xl md:w-80 lg:w-96 font-medium">
            Effortlessly Sell Your Junk Car
          </p>
          <p className="text-xs font-light md:w-80">
            When it comes to selling your scrap car, our company offers several
            key benefits. We make the process convenient, fast, and hassle-free.
          </p>
        </div>
        <div className="w-60 flex items-center justify-center">
          <img src={freesell} alt="sell" />
        </div>
      </div>
      <hr />
      <div
        className="flex items-center justify-center flex-col text-center font-poppins py-10"
        id="contact"
      >
        <p className="text-xl md:text-3xl lg:text-5xl font-normal pb-2">
          Reach Out
        </p>
        <p className="text-xs md:text-sm lg:text-base text-gray-500">
          Provide your contact information and car details for a quick
          assessment.
        </p>
        <ContactForm />
      </div>
      <hr />
      <div className="py-10 flex items-start px-3 md:px-10 lg:px-20 font-poppins flex-col">
        <p className="text-2xl font-medium lg:text-4xl pl-5">
          FAQ<span>s</span>
        </p>
        <p className="text-xs pt-3 text-gray-500 pl-5">
          Find answers to commonly asked questions about selling your scrap car
          hassle-free.
        </p>
        {answers.map((answer) => (
          <div
            key={answer.id}
            className="flex flex-col items-start gap-y-3 p-5 border-b-2 font-poppins border-gray-300"
          >
            <p className="text-lg font-normal">{answer.title}</p>
            <p className="text-xs font-light">{answer.description}</p>
          </div>
        ))}
        <div className="px-5 pt-5 flex items-start flex-col font-poppins">
          <p className="md:text-3xl text-2xl py-2">Still have a Questions?</p>
          <p className="text-sm text-gray-400">
            Contact our team for further assistance
          </p>
        </div>
      </div>
      <div className=""></div>
    </>
  );
};

export default SellScrapCars;
