import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Home from "../Home/Home";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import BuyAutoParts from "../BuyAutoParts/BuyAutoParts";
import SellScrapCars from "../SellScrapCars/SellScrapCars";
import ContactUs from "../ContactUs/ContactUs";
import ScrollToTop from "../Components/ScrollToTop";

const PageRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/buy-auto-parts" element={<BuyAutoParts />} />
        <Route path="/sell-scrap-cars" element={<SellScrapCars />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
