import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

import { card, whatWeDo } from ".";
import products from "./assests/products.webp";

const Sell = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1040);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1040);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayedCard = isSmallScreen ? card.slice(0, 9) : card;

  return (
    <>
      {" "}
      <div className="flex items-center justify-center pt-10 flex-col 2xl:pt-20">
        <div className="text-center font-poppins w-full flex items-center flex-col">
          <p className="text-4xl font-semibold pb-3 lg:text-6xl">
            Products We <span className="text-red">Sell</span>
          </p>
          <p className="text-sm font-medium w-[80%] pb-10">
            Explore the collection of wide range of Products that we offer
          </p>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={products}
            alt="products"
            className="mx-auto absolute opacity-10 min-h-screen w-full mb-80 lg:mb-0"
          />
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-10 justify-center items-center h-match relative">
            {displayedCard.map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-xl lg:rounded-2xl overflow-hidden"
              >
                <div className="text-center pt-3">
                  <p className="md:text-xl font-semibold text-sm font-poppins">
                    {item.title}
                  </p>
                </div>
                <img
                  src={item.image}
                  alt={item.name}
                  className="md:w-full md:h-60 object-cover object-center w-24 h-24 mx-auto"
                />
              </div>
            ))}
          </div>
        </div>
        <NavLink
          to="/buy-auto-parts"
          className="text-black z-40 border rounded-3xl border-black py-2 px-4 mt-10 lg:mt-20 mb-10 font-poppins hover:bg-red hover:text-white transition duration-300 ease-in-out cursor-pointer"
        >
          View All Products
        </NavLink>
      </div>
      <div className="flex items-center justify-center flex-col pt-5 xl:pt-20">
        <p className="text-4xl font-poppins font-semibold text-gray-500 pb-10">
          What We Do!
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-1 pb-10">
          {whatWeDo.map((item, index) => (
            <div
              key={index}
              className="items-center justify-center text-center w-full flex flex-col font-poppins pb-5"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-40 h-40 object-cover object-center mx-auto"
              />
              <p className="text-2xl font-semibold py-5">{item.title}</p>
              <p className="text-sm font-medium w-[60%] pb-5">
                {item.description}
              </p>
              <NavLink
                to="/services"
                className="flex items-center justify-center text-base gap-2 bg-white hover:text-white hover:bg-red text-gray-600 font-medium py-2 px-4 rounded-3xl"
              >
                <p>Learn More</p>
                <IoIosArrowForward />
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sell;
