import React from "react";

import whatsapp from "./assets/whatsapp.svg";
import facebook from "./assets/facebook.svg";
import insta from "./assets/instagram.svg";

const Links = () => {
  return (
    <div className="flex items-center justify-center flex-col text-black z-50 fixed right-1 bg-gray-200 p-1 rounded-lg gap-y-5 bottom-24">
      <a
        href="https://wa.me/919944994421"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsapp} alt="WhatsApp" className="w-10 h-10" />
      </a>
      <a
        href="https://www.facebook.com/coimbatoreautoparts?mibextid=qi2Omg&rdid=dT1fl4zDI35dyzIC&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F9pRsNaVhEW2DvL5x%2F%3Fmibextid%3Dqi2Omg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebook} alt="Facebook" className="w-7 h-7" />
      </a>
      <a
        href="https://www.instagram.com/coimbatoreautoparts/"
        target="_blank"
        rel="noopener noreferrer"
        className="mb-3"
      >
        <img src={insta} alt="Instagram" className="w-7 h-7" />
      </a>
    </div>
  );
};

export default Links;
