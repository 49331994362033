const customersTerms = [
  "Guaranteed 100% Dispatch Rate",
  "Free Parcel Service from CBE to Parcel Office (charges apply for parcel delivery)",
  "Returns accepted within 3 to 7 days for mismatched, unsuitable, or non-functional products",
  "Order via live chat or phone",
  "Secure Payment Options - We offer a variety of secure payment methods to ensure your transactions are safe.",
  "Customer Support - Our dedicated customer support team is available to assist you with any queries or issues.",
  "Privacy Guaranteed - Your personal information is protected and will never be shared without your consent.",
];

const sellerTerms = [
  "Confirm the payment return or replacement within 24 hours of receiving the product.",
  "Payments return process initiated within seven days of the claim.",
  "If the original product marking is not present, the refund will be canceled.",
  "Recovery within a 100km radius is free; charges apply beyond this range.",
  "All products must be returned in their original condition, with all accessories and packaging.",
  "Sellers are responsible for the quality and authenticity of their products.",
  "Disputes must be resolved through our customer service team within 30 days.",
];

const privacyPolicy = [
  "We collect personal information to provide you with the best possible service.",
  "We do not share your personal information with third parties without your consent.",
  "We use cookies to improve your browsing experience and provide personalized content.",
  "You can opt-out of receiving promotional emails at any time.",
  "We take all necessary precautions to protect your personal information from unauthorized access.",
  "We comply with all relevant data protection regulations to ensure your privacy is protected.",
  "If you have any questions or concerns about our privacy policy, please contact us.",
  "We reserve the right to update our privacy policy at any time.",
];

const legal = [
  "Terms and Conditions",
  "Privacy Policy",
  "Refund Policy",
  "Shipping Policy",
  "Cookie Policy",
  "GDPR Compliance",
  "Disclaimer",
  "Terms of Service",
  "Acceptable Use Policy",
  "Intellectual Property Policy",
  "Code of Conduct",
  "Contact Us",
];

export { customersTerms, sellerTerms, privacyPolicy, legal };
