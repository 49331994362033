import React, { useState, useRef, useEffect } from "react";
import { answers } from "../../SellScrapCars/components/sell";

const FAQ = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div>
      <button onClick={toggleModal} className="text-gray-500">
        FAQ
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 p-5 flex justify-center items-center z-50">
          <div
            ref={modalRef}
            className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center"
          >
            <div className="mb-4">
              {answers.map((answer) => (
                <div key={answer.id} className="mb-2 last:mb-0 text-black">
                  <p className="text-lg font-semibold">{answer.title}</p>
                  <p className="text-sm">{answer.description}</p>
                </div>
              ))}
            </div>
            <button
              onClick={toggleModal}
              className="mt-4 px-4 py-2 bg-red-500 text-black rounded hover:bg-red-700 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQ;
