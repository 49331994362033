import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

import cto from "./assets/cto.webp";
import banner from "./assets/cus-banner.webp";
import customer from ".";

const Customers = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayedCustomers = isSmallScreen ? customer.slice(0, 3) : customer;
  return (
    <>
      <div className="flex items-center justify-center py-20 md:py-0 lg:py-20">
        <div className="flex items-center justify-center flex-col font-poppins z-20">
          <hr className="h-0.5 bg-gray-400 w-[80%] rounded-3xl" />
          <p className="text-center w-[80%] py-4 text-sm font-medium pt-7">
            As CTO of our auto parts store,{" "}
            <span className="text-red">Mr. Muthar</span> leverages advanced
            technologies to enhance operations and customer experience. By
            implementing state-of-the-art systems and online platforms, he
            ensures seamless access to high-quality auto parts. His vision
            drives our commitment to superior service and innovation in
            automotive retail.
          </p>
          <div className="flex items-center justify-center flex-col w-full pt-5">
            <img
              src={cto}
              alt="cto"
              className="w-16 rounded-full absolute z-10 right-36 lg:right-80 2xl:right-96 md:right-52 object-center object-cover h-16"
            />
            <div className="relative flex items-center justify-center flex-col w-full font-poppins">
              <p className="text-right w-[70%] text-base font-semibold">
                Muthar
              </p>
              <hr className="h-0.5 bg-gray-400 w-[80%] rounded-3xl" />
              <p className="text-right w-[73%] lg:w-[71%] text-base font-semibold">
                CTO <span className="text-red">CAP</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-col text-center mx-auto pb-10 md:pt-10 lg:pt-0">
        <div className="z-10 min-h-screen">
          <p className="text-xl lg:text-3xl lg:font-semibold font-poppins font-medium 2xl:text-4xl">
            What our happy customers says.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-10 w-96 lg:w-[70%] mx-auto md:w-[95%]">
            {displayedCustomers.map((cus, index) => (
              <div
                key={index}
                className={`rounded-lg shadow-lg border-gray-300 border-2 p-5 font-poppins flex flex-col items-center justify-center ${
                  [1, 3, 5].includes(index) ? "bg-black text-white" : "bg-white"
                }`}
              >
                <p className="text-center py-4 text-xs font-medium">
                  {cus.description}
                </p>
                <div className="flex flex-col items-start justify-start w-full md:flex-row lg:justify-between lg:items-center lg:pt-5 mx-auto lg:ml-10">
                  <div className="flex flex-col w-full">
                    <p className="text-left text-base font-semibold">
                      {cus.name}
                    </p>
                    <div className="flex flex-col justify-start items-start w-full text-gray-400 pt-2">
                      <p className="text-left text-xs font-light">
                        {cus.city}, {cus.state}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start justify-start w-full pt-2 flex-col text-center">
                    <div className="flex items-center justify-center pb-1">
                      <p className="text-center">Ratings</p>
                    </div>
                    <div className="flex items-center justify-center">
                      {[...Array(5)].map((_, index) => {
                        const starValue = index + 1;
                        if (starValue <= Math.floor(cus.star)) {
                          return (
                            <FaStar key={index} className="text-yellow-500" />
                          );
                        } else if (
                          starValue === Math.ceil(cus.star) &&
                          !Number.isInteger(cus.star)
                        ) {
                          return (
                            <FaStarHalfAlt
                              key={index}
                              className="text-yellow-500"
                            />
                          );
                        } else {
                          return (
                            <FaRegStar
                              key={index}
                              className="text-yellow-500"
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center pt-10">
            <a
              href="https://www.google.com/search?q=coimbatoreautoparts&oq=coimbatoreautoparts&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg8MggIAhBFGCcYOzIJCAMQABgKGIAEMgoIBBAAGIAEGKIEMgoIBRAAGIAEGKIEMgYIBhBFGDwyBggHEEUYPNIBCDMyNjhqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8&lqi=ChRjb2ltYmF0b3JlIGF1dG9wYXJ0c0iI2dbB_K2AgAhaJhAAEAEYABgBIhRjb2ltYmF0b3JlIGF1dG9wYXJ0cyoGCAIQABABkgEVdXNlZF9hdXRvX3BhcnRzX3N0b3JlmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU5DWnpWSFNUbEJSUkFCqgE8EAEyHhABIhqTggC1ra7fKdFWN_OClhwHdMnFZPwarxJByjIYEAIiFGNvaW1iYXRvcmUgYXV0b3BhcnRz#lkt=LocalPoiReviews&rlimm=462062101830153957"
              className="px-4 py-2 bg-red text-white rounded-full shadow-md hover:bg-red transition duration-300"
            >
              See All Reviews
            </a>
          </div>
        </div>
        <img
          src={banner}
          alt="banner"
          className="w-full opacity-40 absolute min-h-screen z-0"
        />
      </div>
      <div className="flex items-center justify-center text-center mx-auto flex-col pb-10 font-poppins z-20">
        <p className="text-xl font-medium lg:text-4xl z-20 pb-3">
          We thrive to serve you the best.
        </p>
        <p className="text-xs lg:text-sm font-normal text-gray-400 py-1 lg:text-gray-500">
          Only 100% Quality tested products are being sold by our brand.
        </p>
        <div className="flex items-center justify-center z-20">
          <NavLink
            to="/contact"
            className="bg-red text-white text-sm font-normal rounded-3xl px-5 py-2 mt-5 lg:mt-10 lg:px-10 lg:py-3 lg:text-base lg:font-medium hover:bg-white hover:text-red hover:border-red border-2 border-red ml-5 lg:ml-10"
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/contact"
            className="text-red text-sm font-normal px-5 py-2 mt-5 lg:mt-10 lg:px-10 lg:py-3 lg:text-base lg:font-medium border-2 border-red rounded-3xl ml-5 lg:ml-10 hover:bg-red hover:text-white hover:border-red"
          >
            Get Quote
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Customers;
