import React from "react";

import whywe from "./assets/whywe.mp4";

const WhyWe = () => {
  return (
    <div className="flex items-center justify-around flex-col lg:flex-row p-5 bg-gray-50">
      <div className="text-center font-poppins pb-3">
        <p className="text-2xl font-semibold pb-5 text-red md:text-4xl xl:text-5xl 2xl:text-6xl">
          Why Used Parts?
        </p>
        <ul className="gap-y-2 flex flex-col text-sm md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl md:pt-5 font-semibold text-left">
          <li>
            <span className="text-red">-</span> 100% Genuine Spares
          </li>
          <li>
            <span className="text-red">-</span> Affordable Price
          </li>
          <li>
            <span className="text-red">-</span> Immediate Availability
          </li>
        </ul>
      </div>
      <div className="lg:w-[90vh]">
        <video loop autoPlay muted>
          <source src={whywe} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default WhyWe;
