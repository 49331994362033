import React from "react";

const Button = ({ text, icon, isActive, onClick }) => {
  return (
    <button
      className={`flex items-center justify-center px-4 py-2 rounded-md transition-colors duration-200 ease-in-out border border-gray-500 font-poppins text-base z-50 ${
        isActive ? "bg-red text-white" : "bg-white text-gray-500"
      }`}
      onClick={onClick}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </button>
  );
};

export default Button;
