import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Navbar from "./Navbar/Navbar";
import Links from "./Links/Links";
import PageRoutes from "./Routes/PageRoutes";
import Footer from "./Footer/Footer";

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Links />
        <div className="pt-20">
          <PageRoutes />
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
