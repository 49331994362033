import React from "react";

import banner from "./assests/banner.webp";
import car from "./assests/car.gif";
import line from "./assests/line.png";
import home from "./assests/home.mp4";
import "./Home.css";
import Info from "./Info/Info";
import Sell from "./Sell/Sell";
import Quality from "./Quality/Quality";
import Customers from "./Customers/Customers";
import WhyWe from "./WhyWe/WhyWe";

const Home = () => {
  return (
    <>
      <div className="bg-white relative">
        <div className="flex flex-col items-center justify-center text-center pt-20 space-y-5 lg:text-5xl text-3xl font-poppins relative z-10">
          <p className="w-80 lg:w-full font-semibold 2xl:text-6xl">
            Get Your <span className="text-red">Car</span> Running Like New
          </p>
          <p className="font-semibold 2xl:text-5xl text-2xl">
            Save Money with us!
            <img src={line} alt="line" className="lg:w-34 w-32 h-2" />
          </p>
          <p className="lg:text-xl text-sm w-60 lg:w-full font-medium">
            100% Premium Used <span className="text-red"> OE </span>Auto Parts &
            Responsible Scrap Car Removal.
          </p>
          <img
            src={car}
            alt="car"
            className="mx-auto mt-5 relative mb-10 lg:h-[65vh] lg:w-[100vh]"
          />
          <div className="w-full">
            <video loop autoPlay muted className="w-full">
              <source src={home} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <img
          src={banner}
          alt="banner"
          className="mx-auto absolute left-1/2 transform -translate-x-1/2 top-0 h-full w-full"
        />
      </div>
      <WhyWe />
      <Info />
      <Sell />
      <Quality />
      <Customers />
    </>
  );
};

export default Home;
